const CryptoJS = require('crypto-js');
export default async function createSignature(secret,rmNumber,txnID,mId){
    try {
    // let sec = secret;
    // let rmn = rmNumber;
    // let txnId = txnID;
    // let mid = mId;
    // let data = rmn + txnId + mid;
    // const hmac = crypto.createHmac("sha512", sec);
    // hmac.update(data);
    // let signature =  hmac.digest('hex');
    // console.log("Signature", signature);
    let sec = secret;
    let rmn = rmNumber;
    let txnId = txnID;
    let mid = mId;
    let data = rmn + txnId + mid;
    const hmac = CryptoJS.HmacSHA512(data, sec);
    let signature = hmac.toString(CryptoJS.enc.Hex);
    console.log("Signature:", signature);
    return signature;
    } catch (err) {
    console.log(err);
    }
}
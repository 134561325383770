import { Button, Grid, TextField, Typography, Zoom } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import UseRadioGroup from "../components/RadioGroup";
import SelectBox from "../components/SelectBox";
import { useSnackDispatchContext } from "../contexts/NotificationBarContext";
import { authenticateUser, registerUser } from "../services/webAuthRegister";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: 20,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Passwordless = () => {
  const [userNumber, setUserNumber] = React.useState("");
  const [radioButtonValue, setRadioButtonValue] = React.useState("");
  const [serverSelectionValue, setServerSelectionValue] = React.useState(
    "https://poc.ashieldhub.com/Ashield"
  );
  const [authenticationVerificationSettingValue, setValue] =
    React.useState("required");
  const snackDispatch: any = useSnackDispatchContext();

  const handleRadioButton = (
    authenticationVerificationSettingValue: string
  ) => {
    setRadioButtonValue(authenticationVerificationSettingValue);
  };
  const handleServerValue = (serverValue: string) => {
    console.log("Server Value", serverValue);
    if (serverValue === "SAAS") {
      setServerSelectionValue("https://saas.ashieldhub.com/Ashield");
    } else if (serverValue === "POC") {
      setServerSelectionValue("https://poc.ashieldhub.com/Ashield");
    } else if (serverValue === "4DA") {
      setServerSelectionValue("https://4da.ashieldhub.com/Ashield");
    } else {
      setServerSelectionValue("http://localhost:9090/Ashield");
    }
    // console.log(serverSelectionValue);
  };
  useEffect(() => {
    console.log(window.location.href);
    console.log(serverSelectionValue);
  }, []);
  return (
    <>
      <Grid
        sx={{ flexGrow: 1 }}
        direction={"row"}
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid maxWidth={500} container>
          <Grid item xs={12}>
            <Item elevation={0}>
              <Typography variant="h4">AShield WebAuth Demo</Typography>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <Typography variant="h6">
                Please select the server for testing
              </Typography>
            </Item>
            <Item elevation={0}>
              <UseRadioGroup
                items={["POC", "SAAS", "4DA"]}
                onChangeCallback={(v) => handleServerValue(v)}
              ></UseRadioGroup>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <TextField
                value={userNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUserNumber(event.target.value);
                }}
                sx={{ minWidth: 300 }}
                fullWidth
                label="Usernumber"
                type="number"
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <UseRadioGroup
                items={["Registration", "Authentication"]}
                onChangeCallback={(v) => handleRadioButton(v)}
              ></UseRadioGroup>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item elevation={0}>
              <Button
                fullWidth
                onClick={() => {
                  let truncatedUserNumber = "";
                  if (userNumber.length == 10) {
                    truncatedUserNumber = userNumber;
                  } else if (userNumber.length == 12) {
                    truncatedUserNumber = userNumber.substring(2, 12);
                  } else if (userNumber.length == 13) {
                    truncatedUserNumber = userNumber.substring(3, 13);
                  } else if (userNumber.length == 11) {
                    truncatedUserNumber = userNumber.substring(1, 11);
                  }
                  setUserNumber(truncatedUserNumber);

                  console.log("New User number:", truncatedUserNumber);
                  radioButtonValue === "Authentication"
                    ? authenticateUser(
                        truncatedUserNumber,
                        (error_code: string, msg: string) => {
                          console.log(`Error code: ${error_code}, msg:${msg}`);
                          snackDispatch({
                            type: "showFailed",
                            msg: "Authentication failed",
                          });
                        },
                        (str: string) => {
                          snackDispatch({
                            type: "show",
                            msg: `Welcome back ${userNumber}`,
                          });
                        },
                        window.location.href + "Ashield/regcomplete",
                        serverSelectionValue
                      )
                    : registerUser(
                        truncatedUserNumber,
                        (error_code: string, msg: string) => {
                          console.log(`Error code: ${error_code}, msg:${msg}`);
                          snackDispatch({
                            type: "showFailed",
                            msg: "Registration failed",
                          });
                        },
                        (str: string) => {
                          snackDispatch({
                            type: "show",
                            msg: `Registration completed`,
                          });
                        },
                        // "http://localhost:3000/Ashield/regcomplete",
                        window.location.href + "Ashield/regcomplete",
                        serverSelectionValue
                      );
                }}
                sx={{ marginX: 0, minWidth: 120 }}
                variant="contained"
              >
                Submit
              </Button>
            </Item>
          </Grid>
          {radioButtonValue === "Authentication" ? (
            <Zoom in={radioButtonValue === "Authentication"}>
              <Grid item xs={12}>
                <Item elevation={0}>
                  <Typography
                    variant="body1"
                    sx={{
                      py: 2,
                      textAlign: "left",
                      textDecoration: "underline",
                      color: "success",
                    }}
                  >
                    Authentication Settings
                  </Typography>
                  <SelectBox
                    label="User Verification"
                    items={["discouraged", "required"]}
                    value={authenticationVerificationSettingValue}
                    setValue={(str) => setValue(str)}
                  />
                </Item>
              </Grid>
            </Zoom>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Passwordless;

export const UNAUTHORIZED = 401;
export const UNKNOWNERROR = 449;
export const FAIL_STATUS_CODES = [401, 403];
export const CHANGE_USER_DATA = "CHANGE_USER_DATA";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const SIGN_DECLARATION = "SIGN_DECLARATION";

// WEB AUTHENTICATION (FIDO) VARIABLES
// export const WEB_AUTH_URL = "https://dev.fido.ashieldhub.com/passwordless/v1/"
export const WEB_AUTH_URL = "";
export const CUS_HEADER = {};
export const WEB_AUTH_ALG = "SHA-256";
// export const WEB_AUTH_MID = "024"
export const WEB_AUTH_MID = "027";
// export const WEB_AUTH_MER_TXN_ID = "12345";
export const WEB_AUTH_MER_TXN_ID = "45454";
// export const WEB_AUTH_SECRET_KEY = "c042f1d545907066a18fa5416d04a12e"
export const WEB_AUTH_SECRET_KEY = "02e74-3c9fe-5f3d6-bfbd69d0151685392e61d6ab";

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SimpleSnackbar from './components/SnackBar';
import { NotificationBarProvider } from './contexts/NotificationBarContext';
import reportWebVitals from './reportWebVitals';
import Passwordless from './views/Passwordless';
import Regcomplete from './views/Regcomplete';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <NotificationBarProvider>
      <SimpleSnackbar />
      <BrowserRouter>
        <Routes> 
          <Route path="/" element={<Passwordless />}></Route>
          <Route path="/Ashield/regcomplete" element={<Regcomplete />}></Route>
        </Routes>
      </BrowserRouter>
    </NotificationBarProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

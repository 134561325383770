import React, { useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { processResp } from "../services/passwordlessweb.js";
import { useSnackDispatchContext } from "../contexts/NotificationBarContext";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: 20,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Regcomplete = () => {
  const snackDispatch: any = useSnackDispatchContext();
  const navigate = useNavigate();
  const errorResp =
    "eyJzdGF0dXMiOjIwMDAyLCJtc2ciOiJUZWxjbyB2ZXJpZmljYXRpb24gZmFpbGVkIn0=";

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param = urlParams.get("resp");
    const regnum = urlParams.get("regnum");

    if (param === null) {
      console.log("in empty resp");
      console.log("Param:", param);
      navigate("/");
      snackDispatch({ type: "showFailed", msg: "Registration Error!" });
    } else if (param === errorResp) {
      navigate("/");
      console.log("in error resp");
      console.log("Param:", param);
      snackDispatch({
        type: "showFailed",
        msg: "Registration Error!",
      });
    } else if (param === "authSuccess") {
      snackDispatch({ type: "show", msg: `Welcome back ${regnum}` });
    } else {
      processResp(
        param,
        (error_code: string, msg: string) => {
          console.log(`Error code: ${error_code}, msg:${msg}`);
          snackDispatch({ type: "showFailed", msg: "Registration failed" });
        },
        (str: string) => {
          snackDispatch({ type: "show", msg: `Registration completed` });
        }
      );
    }
    // if (param != null && param !== errorResp && param !== "authSuccess") {
    //   processResp(
    //     param,
    //     (error_code: string, msg: string) => {
    //       console.log(`Error code: ${error_code}, msg:${msg}`);
    //       snackDispatch({ type: "showFailed", msg: "Registration failed" });
    //     },
    //     (str: string) => {
    //       snackDispatch({ type: "show", msg: `Registration completed` });
    //     }
    //   );
    // } else if (param == errorResp) {
    //   navigate("/");
    //   console.log("in error resp");
    //   processResp(
    //     param,
    //     (error_code: string, msg: string) => {
    //       console.log(`Error code: ${error_code}, msg:${msg}`);
    //       snackDispatch({ type: "showFailed", msg: "Registration failed" });
    //     },
    //     (str: string) => {
    //       snackDispatch({ type: "show", msg: `Registration completed` });
    //     }
    //   );
    // } else if (param == "authSuccess") {
    //   snackDispatch({ type: "show", msg: `Welcome back ${regnum}` });
    // } else if (param == null) {
    //   console.log("in empty resp");
    //   navigate("/");
    //   snackDispatch({ type: "showFailed", msg: "Register First!" });
    // }
  }, [errorResp]);

  return (
    <div>
      <>
        <Grid
          sx={{ flexGrow: 1 }}
          direction={"row"}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid maxWidth={500} container>
            <Grid
              item
              xs={12}
              direction={"column"}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Item elevation={0}>
                <Typography variant="h4">AShield WebAuth Demo</Typography>
              </Item>
              <Item elevation={0}>
                <Typography variant="h6">Welcome to Home page</Typography>
              </Item>
              <Item elevation={0}>
                <Button
                  fullWidth
                  onClick={() => {
                    navigate("/");
                  }}
                  sx={{ marginX: 0, minWidth: 120 }}
                  variant="outlined"
                  color="error"
                >
                  LogOut
                </Button>
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </>
    </div>
  );
};

export default Regcomplete;

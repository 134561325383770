import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';

type SelectBoxProps = { label: string, value: string, setValue: (str: string) => void, items: string[] }
export default function SelectBox({ label, value, setValue, items }: SelectBoxProps) {

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };

    return (
        <React.Fragment>
            <Box sx={{ minWidth: 120 }}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-select-small-label">{label}</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={value}
                        label={label}
                        onChange={handleChange}
                    >
                        {items.map((item) => {
                            return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Box>
        </React.Fragment>
    );
}

import React, { createContext, useContext, useReducer } from "react";

type SnackData = { open: boolean, msg: string, severity: "error" | "warning" | "info" | "success" }

const snackData: SnackData = { open: false, msg: "", severity: "success" }

const SnackContext = createContext(snackData);

const SnackDispatchContext: any = createContext(null);


export function NotificationBarProvider({ children }: any) {
    const [snackData1, dispatch] = useReducer(snackDataReducer, snackData as never);
    return (< SnackContext.Provider value={snackData1} >
        <SnackDispatchContext.Provider value={dispatch}>
            {children}
        </SnackDispatchContext.Provider>
    </SnackContext.Provider >
    )
}


export function useSnackContext() {
    return React.useContext(SnackContext);
}


export function useSnackDispatchContext() {
    return useContext(SnackDispatchContext)
}


function snackDataReducer(_data: SnackData, action: { type: "showSuccess" | "show" | "showFailed" | "hide"; msg: string }): SnackData | undefined {
    switch (action.type) {
        case "showSuccess":
            return { open: true, msg: action.msg, severity: "success" }
        case "show":
            return { open: true, msg: action.msg, severity: "success" }
        case "showFailed":
            return { open: true, msg: action.msg, severity: "error" }
        case "hide":
            return { open: false, msg: action.msg, severity: "info" }
    }
}

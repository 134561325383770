import {
  CUS_HEADER,
  WEB_AUTH_MER_TXN_ID,
  WEB_AUTH_MID,
  WEB_AUTH_SECRET_KEY,
} from "../utils/consts";
import createSignature from "./generateSignature";
import {
  authenticateUser as webauth_auth,
  registerUser as webauth_register,
} from "./passwordlessweb.js";

// here fCB => failure callback function, sCB => successcallback
export const registerUser = async (
  usernumber: string,
  fCB: Function,
  sCB: Function,
  url: string,
  selectedServer: string
) => {
  // if (10 > usernumber.length) {
  //     fCB("CL_ERR_1001", "Invalid Registration request");
  //     return;
  //   }
  //    if (10 == usernumber.length) {
  //      usernumber = "91" + usernumber;
  //    } else if (11 == usernumber.length) {
  //      usernumber = "91" + usernumber.substring(1, 11);
  //    } else if (13 == usernumber.length) {
  //      usernumber = usernumber.substring(1, 13);
  //    }
  const signature = await createSignature(
    WEB_AUTH_SECRET_KEY,
    usernumber,
    WEB_AUTH_MER_TXN_ID,
    WEB_AUTH_MID
  );
  await webauth_register(
    usernumber,
    WEB_AUTH_MID,
    WEB_AUTH_MER_TXN_ID,
    url,
    signature,
    CUS_HEADER,
    fCB,
    sCB,
    selectedServer
  );
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const param = urlParams.get('resp');
  // processResp(param, fCB, sCB);
};

export const authenticateUser = async (
  usernumber: string,
  fCB: Function,
  sCB: Function,
  url: string,
  selectedServer: string
) => {
  // init(WEB_AUTH_URL, WEB_AUTH_ALG);
  // if (10 > usernumber.length) {
  //     fCB("CL_ERR_1001", "Invalid Registration request");
  //     return;
  //   }
  //    if (10 === usernumber.length) {
  //      usernumber = "91" + usernumber;
  //    } else if (11 === usernumber.length) {
  //      usernumber = "91" + usernumber.substring(1, 11);
  //    } else if (13 === usernumber.length) {
  //      usernumber = usernumber.substring(1, 13);
  //    }
  const signature = await createSignature(
    WEB_AUTH_SECRET_KEY,
    usernumber,
    WEB_AUTH_MER_TXN_ID,
    WEB_AUTH_MID
  );
  webauth_auth(
    usernumber,
    WEB_AUTH_MID,
    WEB_AUTH_MER_TXN_ID,
    signature,
    CUS_HEADER,
    fCB,
    sCB,
    url,
    selectedServer
  );
};

import FormControlLabel, {
    FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import React from 'react';
type UseRadioGroupProps = { items: string[]; onChangeCallback: (value: string) => void }

interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
    <FormControlLabel {...props} />
))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
    },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}
export default function UseRadioGroup({ items, onChangeCallback }: UseRadioGroupProps) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeCallback((event.target as HTMLInputElement).value)
    };

    return (
        <React.Fragment>
            <RadioGroup name="use-radio-group" onChange={handleChange} defaultValue={items[0]}>
                {items.map((item) => {
                    return (
                        <MyFormControlLabel key={item} value={item} label={item} control={<Radio />} />
                    )
                })}
            </RadioGroup>
        </React.Fragment>
    );
}

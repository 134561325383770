// @ts-nocheck
// @ts-ignore
// eslint-disable-next-line no-unused-expressions
/*
    Copyright 2023 AShield Technologies

    Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

    The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
// import {logger} from "./logger";
const logger = console;

function arrayBufferToString(arrayBuffer) {
  return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
}
// function for cahnging to ArrayBuffer
function toArrayBuffer(e) {
  const t = e.replace(/-/g, "+").replace(/_/g, "/"),
    n = (4 - (t.length % 4)) % 4,
    r = t.padEnd(t.length + n, "="),
    o = atob(r),
    a = new ArrayBuffer(o.length),
    i = new Uint8Array(a);
  for (let e = 0; e < o.length; e++) i[e] = o.charCodeAt(e);
  return a;
}

// function for changing ArrayBuffer to base64
function arrayBufferToBase64(e) {
  const t = new Uint8Array(e);
  let n = "";
  for (const e of t) n += String.fromCharCode(e);
  return btoa(n).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

function idStringToArrayBuffer(e) {
  for (let i = 0; i < e.length; i++) {
    e[i].id = toArrayBuffer(e[i].id);
  }
  return e;
}
// var serverBaseUrl = 'http://localhost:9090/Ashield';
// var serverBaseUrl = 'https://poc.ashieldhub.com/Ashield';
// var serverBaseUrl = 'https://saas.ashieldhub.com/Ashield';
var algo = "SHA-256";
/*sbUrl : Server URL from which the request will be proxied
algo : Alogrithm same which will be decided from server
*/
async function init(sbUrl, alg) {
  algo = alg;
  // serverBaseUrl = sbUrl;
}
// Register function: takes values from the user
/* un is nothing the userId which would be registered and uniquely identified
mid : MerchantID provided by AShield team
merTxnId : TransactionID created for this request for further reference
onError and onSuccess or just callback functions invoked accordingly 
*/
export async function registerUser(
  rmn,
  mid,
  merTxnId,
  rdurl,
  signature,
  cusHeaders,
  onError,
  onSuccess,
  serverBaseUrl
) {
  let msg = "Unexpected error";
  // Data passing for preregister
  try {
    let status = 0;
    let headers = {
      "Content-Type": "application/json",
      "x-sdk-type": "web",
      "x-sdk-ver": "1.001",
    };
    Object.assign(headers, cusHeaders);
    console.log(rdurl);
    if (!signature || 0 == signature.length) {
      onError("CL_ERR_1001", "Invalid Registration request");
      return;
    }
    if (10 > rmn.length) {
      onError("CL_ERR_1001", "Invalid Registration request");
      return;
    }
    //  if (10 == rmn.length) {
    //    rmn = "91" + rmn;
    //  } else if (11 == rmn.length) {
    //    rmn = "91" + rmn.substring(1, 11);
    //  } else if (13 == rmn.length) {
    //    rmn = rmn.substring(1, 13);
    //  }
    let epuk = localStorage.getItem(rmn + "_" + "epuk");
    let epik = localStorage.getItem(rmn + "_" + "epik");

    if (null == epuk || 0 == epuk.length) {
      // Create public-private key pair
      try {
        let keypar = await window.crypto.subtle.generateKey(
          {
            name: "RSA-OAEP",
            modulusLength: 4096,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: algo,
          },
          true,
          ["encrypt", "decrypt"]
        );
        let ek = await window.crypto.subtle.exportKey("spki", keypar.publicKey);
        epuk = arrayBufferToBase64(ek);
        localStorage.setItem(rmn + "_" + "epuk", epuk);

        let epk = await window.crypto.subtle.exportKey(
          "pkcs8",
          keypar.privateKey
        );

        epik = arrayBufferToBase64(epk);
        localStorage.setItem(rmn + "_" + "epik", epik);
      } catch (exp) {
        // Throw error
        logger.error("Error occurred during registration:", exp);
        onError("CL_ERR_1003", "Client Error. " + exp.message);
        return;
      }
    }
    // localStorage.setItem(un + "_" + "cid",  credential.id);
    epuk = localStorage.getItem(rmn + "_" + "epuk");
    // PUK Must be sent as part of request
    // So must wait for above promises
    const credentialData = {
      regnum: rmn,
      epuk: epuk,
      mid: mid,
      merTxnId: merTxnId,
      origin: window.location.hostname,
      rdurl: rdurl,
      signature: signature,
    };

    let registerResponse = await fetch(serverBaseUrl + "/web/telco/register", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(credentialData),
    });

    let serverRegisterResponse = await registerResponse.json();
    msg = serverRegisterResponse.msg;
    status = serverRegisterResponse.status;

    if (!registerResponse.ok) {
      logger.info("Registeration failed!");
      logger.trace(
        "Server response: " + JSON.stringify(serverRegisterResponse)
      );
      onError(
        "CL_ERR_1004",
        "Registration failed. Unable to process Registration request"
      );
      return;
    }
    if (status == 4102) {
      // Show error message
      onError(
        "CL_ERR_1004",
        "Registration failed. Unable to process Registration request"
      );
      return;
    }
    // Share must be in encrypted format
    // Decrypt and store it
    if (serverRegisterResponse.resp) {
      // Once the response is available d
      let epik = localStorage.getItem(rmn + "_" + "epik");
      if (undefined == epik || 0 == epik.length) {
        onError("CL_ERR_1003", "Client Error. ");
        return;
      }
      window.crypto.subtle
        .importKey(
          "pkcs8",
          toArrayBuffer(epik),
          { name: "RSA-OAEP", hash: { name: algo } },
          true,
          ["decrypt"]
        )
        .then(function (pk) {
          window.crypto.subtle
            .decrypt("RSA-OAEP", pk, toArrayBuffer(serverRegisterResponse.resp))
            .then(function (decdata) {
              let url = arrayBufferToString(decdata);
              window.location.href = url;
            });
        });
    }
  } catch (error) {
    logger.error("Error occurred during registration:", error);
    onError("CL_ERR_1003", "Client Error. " + error.message);
  }
}

// Authenticate the user

// AuthenticateUser function: takes values from the user
/* rmn is the userId which was registered earlier and uniquely identified
mid : MerchantID provided by AShield team
merTxnId : TransactionID created for this request for further reference
signature : generate the signature as per the  generate signature reference
onError and onSuccess or just callback functions invoked accordingly 
*/
export async function authenticateUser(
  rmn,
  mid,
  merTxnId,
  signature,
  cusHeaders,
  onError,
  onSuccess,
  rdurl,
  serverBaseUrl
) {
  // Generate a challenge (random string)
  try {
    let headers = {
      "Content-Type": "application/json",
      "x-sdk-type": "web",
      "x-sdk-ver": "1.001",
    };
    if (!signature || 0 == signature.length) {
      onError("CL_ERR_1001", "Invalid Registration request");
      return;
    }

    if (10 > rmn.length) {
      onError("CL_ERR_2001", "Invalid authentication request");
      return;
    }
    //  if (10 == rmn.length) {
    //    rmn = "91" + rmn;
    //  } else if (11 == rmn.length) {
    //    rmn = "91" + rmn.substring(1, 11);
    //  } else if (13 == rmn.length) {
    //    rmn = rmn.substring(1, 13);
    //  }
    Object.assign(headers, cusHeaders);
    let msg = "Unexpected Error";
    let status = 0;

    let epuk = localStorage.getItem(rmn + "_" + "epuk");
    let epik = localStorage.getItem(rmn + "_" + "epik");
    let asAuthData = {
      share: localStorage.getItem(rmn + "_" + "share"),
      txnId: localStorage.getItem(rmn + "_" + "txnId"),
      regnum: rmn,
      mid: mid,
      merTxnId: merTxnId,
      signature: signature,
      origin: window.location.hostname,
      silentauth: true,
    };
    if (null == epuk || null == epik) {
      // Unexpected error throw exception
      onError("CL_ERR_2002", "Invalid Input. Register and try again");
      //  onError("User verification is must. Unexcepted error");
    } else {
      // Perform the server authenticate request
      let sresp = await fetch(serverBaseUrl + "/web/telco/authenticate", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(asAuthData),
      });
      let serverAuthenticateResponse = await sresp.json();
      msg = serverAuthenticateResponse.msg;
      status = serverAuthenticateResponse.status;
      console.log(serverAuthenticateResponse, "Auth response");
      if (!sresp.ok) {
        logger.info("Authentication failed!");
        logger.trace(
          "Server Authentication Response: " +
            JSON.stringify(serverAuthenticateResponse)
        );
        onError(
          "CL_ERR_2003",
          "Authentication Failed. Try Registration again."
        );
        return;
      }
      if (status == 4302) {
        onError(
          "CL_ERR_2003",
          "Authentication Failed. Try Registration again."
        );
        return;
      }
      if (serverAuthenticateResponse.resp) {
        processAsResp(rmn, JSON.parse(serverAuthenticateResponse.resp), rdurl);
        // window.location.href = rdurl;
        // onSuccess("Authenticated");
      }
    }
  } catch (error) {
    logger.error("Error occurred during authentication:", error);
    onError("CL_ERR_2004", "Client Error. " + error.message);
  }
}

export async function processResp(rdResp, onError, onSuccess) {
  if (null == rdResp || 0 == rdResp.length) {
    onError("CL_ERR_1005", "Registration failed. Empty response");
    return;
  }
  let abResp = toArrayBuffer(rdResp);
  let strResp = arrayBufferToString(abResp);

  let jsonResp = JSON.parse(strResp);
  console.log("Process Response ", jsonResp);
  let status = jsonResp.status;
  let msg = jsonResp.msg;
  if (status !== 20001) {
    onError(
      "CL_ERR_1006",
      "Registration failed. Unable to process Registration request"
    );
    return;
  }
  let un = jsonResp.un;
  let asTxnId = jsonResp.asTxnId;
  let encdata = jsonResp.eshare;
  let epik = localStorage.getItem(un + "_" + "epik");

  if (undefined == epik || 0 == epik.length) {
    onError("CL_ERR_1007", "Registration failed. Registration not initiated");
    return;
  }
  window.crypto.subtle
    .importKey(
      "pkcs8",
      toArrayBuffer(epik),
      { name: "RSA-OAEP", hash: { name: algo } },
      true,
      ["decrypt"]
    )
    .then(function (pk) {
      window.crypto.subtle
        .decrypt("RSA-OAEP", pk, toArrayBuffer(encdata))
        .then(function (decdata) {
          let share = arrayBufferToString(decdata);
          localStorage.setItem(un + "_" + "txnId", asTxnId);
          localStorage.setItem(un + "_" + "share", share);
          onSuccess("Registered");
        });
    });
}

function processAsResp(un, resp, rdurl) {
  if ("eshare" in resp) {
    let encdata = resp.eshare;
    let epik = localStorage.getItem(un + "_" + "epik");
    if (undefined == epik || 0 == epik.length) {
      return;
    }
    window.crypto.subtle
      .importKey(
        "pkcs8",
        toArrayBuffer(epik),
        { name: "RSA-OAEP", hash: { name: algo } },
        true,
        ["decrypt"]
      )
      .then(function (pk) {
        window.crypto.subtle
          .decrypt("RSA-OAEP", pk, toArrayBuffer(encdata))
          .then(function (decdata) {
            let share = arrayBufferToString(decdata);
            window.location.href = rdurl;
            localStorage.setItem(un + "_" + "txnId", resp.asTxnId);
            localStorage.setItem(un + "_" + "share", share);
            window.location.href = rdurl + `?resp=authSuccess&regnum=${un}`;
          });
      });
  }
}
